.dbaColorInput {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 4px;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}

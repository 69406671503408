/* roboto-100 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./assets/fonts/roboto-v30-latin_cyrillic-100.woff2') format('woff2');
}
/* roboto-300 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/roboto-v30-latin_cyrillic-300.woff2') format('woff2');
}
/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/roboto-v30-latin_cyrillic-regular.woff2') format('woff2');
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./assets/fonts/roboto-v30-latin_cyrillic-500.woff2') format('woff2');
}
/* roboto-700 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/roboto-v30-latin_cyrillic-700.woff2') format('woff2');
}
/* roboto-900 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./assets/fonts/roboto-v30-latin_cyrillic-900.woff2') format('woff2');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}

.leaflet-bar.leaflet-control {
  border: none;
  box-shadow: 1px 2px 6px rgba(56, 56, 116, 0.45);
}

.cronGeneratorContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.error {
  color: red;
}
.cronGeneratorRow {
  display: flex;
  gap: 1.25rem;
}

// Выравниваем маркеры для отображения на карте

.leaflet-marker-icon {
  .leaflet-custom-marker {
    position: relative;
  }

  .pin1 {
    left: -6px !important;
    top: -25px !important;
  }

  .pin2 {
    left: -8px !important;
    top: -23px !important;
  }

  .square {
    left: -6px !important;
    top: -5px !important;
  }

  .circle {
    left: -6px !important;
    top: -6px !important;
  }

  .triangleUp {
    left: -5px !important;
    top: -8px !important;
  }

  .triangleDown {
    left: -5px !important;
    top: -8px !important;
  }

  .rhombus {
    left: -6px !important;
    top: -20px !important;
  }

  .cross {
    left: 2px !important;
    top: -6px !important;
  }

  .default {
  }
}
